class AdminBookTranslation {
    constructor() {
        $('.js-request-type-select').change(function() {
            $('.request-type-block').removeClass('active');
            $(".js-request-type-block--" + this.value).addClass('active');
        });
    }
}

export default AdminBookTranslation
