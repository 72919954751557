window.AskueIndication = class AskueIndication {
    constructor() {
        this.filters = {
            date_start: null,
            date_end: null,
            id_counter: null,
            typeFilter: 'indications'
        };

        this.initFilters();
    }

    initFilters() {
        var self = this;

        $('.page-about-block__link-item').attr('href', '/askue/export_xls/indications?' + jQuery.param(self.filters));
        $('.js-askue-indication-filter-field').each(function () {
            var name_field = $(this).attr('name');

            self.filters[name_field] = $(this).val();
        });

        $('.js-askue-indication-filter-field').on('change', function () {
            var name_field = $(this).attr('name');

            self.filters[name_field] = $(this).val();

            self.filterIndication()
        })
    }

    filterIndication() {
        var self = this;

        $('.page-about-block__link-item').attr('href', '/askue/export_xls/indications?' + jQuery.param(self.filters));
        $.ajax({
            type: "GET",
            dataType: 'json',
            url: '/askue/indications_filter',
            data: self.filters,
            success: function(data, status, xhr ) {
                self.editDailyReadings(data);
            },

            error: function (data) {
                console.log(data)
            }
        });
    }


    editDailyReadings(data) {
        console.log(data);
        if(data.askue_daily_readings){
            $('.askue-hourly-reading__counters-wrapper').html(data.askue_daily_readings)

        }
    }
};

export default window.AskueIndication