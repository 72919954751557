import M from "materialize-css";

window.AddressSearch = class AddressSearch {
    constructor() {
        var result_search_string = '';

        $('.js-address-search').on('change', function () {
            var addressSearchSection = $(this).parents('.js-address-search-section');
            var region = addressSearchSection.find('.js-address-search--address_region').val();

            if(region){
                if(region.length > 0 && addressSearchSection.find('.js-address-search--address_region').attr('name').includes('connection_object')){
                    region = 'Кемеровская область '
                }
            }

            result_search_string = region + ' ' + addressSearchSection.find('.js-address-search--address_city').val() + ' ' + addressSearchSection.find('.js-address-search--address_street').val() + ' ' + addressSearchSection.find('.js-address-search--address_house').val() + ' ' + addressSearchSection.find('.js-address-search--address_housing').val();

            if(addressSearchSection.find('.js-address-search--address_city').val().length > 0){
                $.ajax({
                    url: '/address_search',
                    type: "GET",
                    dataType: 'json',
                    data: {
                        search_string: result_search_string,
                    },
                    success: function (data, status, xhr) {
                        var result = JSON.parse(data.dadata_result);
                        console.log(result);

                        if(result.suggestions === undefined) {
                            if(!addressSearchSection.find('.js-address-search--address_region').attr('name').includes('connection_object')){
                                if (result.region.length > 0) {
                                    addressSearchSection.find('.js-address-search--address_region').val(result.region);
                                }
                            }

                            if (result.area.length > 0) {
                                addressSearchSection.find('.js-address-search--address_district').val(result.area);
                            }

                            if (result.city.length > 0) {
                                addressSearchSection.find('.js-address-search--address_city').val(result.city);
                            }

                            if (result.street.length > 0) {
                                addressSearchSection.find('.js-address-search--address_street').val(result.street);
                            }

                            if (result.house.length > 0) {
                                addressSearchSection.find('.js-address-search--address_house').val(result.house);
                            }

                            addressSearchSection.find('.js-address-search--address_housing').val();

                            if (result.flat.length > 0) {
                                addressSearchSection.find('.js-address-search--address_appartment').val(result.flat);
                            }

                            if (result.postal_code.length > 0) {
                                addressSearchSection.find('.js-address-search--address_index').val(result.postal_code);
                            }

                            M.updateTextFields();
                        }
                    },

                    error: function (data) {
                        console.log(data);
                    }
                });
            }

        });


        $('.js-full-address-search').on('change', function () {
            result_search_string = $(this).val();
            var addressInput = $(this);

            $.ajax({
                url: '/address_search',
                type: "GET",
                dataType: 'json',
                data: {
                    search_string: result_search_string
                },
                success: function (data, status, xhr) {
                    var result = JSON.parse(data.dadata_result);
                    $(addressInput).val(result.value)
                },

                error: function (data) {
                    console.log(data);
                }
            });
        });
    }
};

export default window.AddressSearch