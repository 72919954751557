window.AskueHourlyReadings = class AskueHourlyReadings {
    constructor() {
        this.filters = {
            date_start: null,
            date_end: null,
            id_counter: null,
            typeFilter: 'hourly_readings'
        };

        this.initGraaphics();
        this.initFilters();
        this.initOpenPopup()
    }

    initGraaphics(){
        $('.askue-hourly-block__graphic').each(function () {
            var max_value = 0;

            $(this).find('.askue-hourly-block__graphic-block').each(function () {
                if(max_value < parseFloat($(this).attr('data-value'))){
                    max_value = parseFloat($(this).attr('data-value'))
                }
            });

            $(this).find('.askue-hourly-block__graphic-block').each(function () {
                var height = parseFloat($(this).attr('data-value')) / max_value * 100;

                $(this).css('height', height + 'px')
            });
        })
    }

    initOpenPopup(){
        $('.askue-hourly-block__preview').click(function () {
            $($(this).parents('.askue-hourly-block')[0]).find('.askue-hourly-block__popup-wrapper').css('display', 'flex')
        });

        $('.askue-hourly-block__popup-header-close').click(function () {
            $(this).parents('.askue-hourly-block__popup-wrapper').css('display', 'none')
        });
    }

    initFilters() {
        var self = this;
        $('.page-about-block__link-item').attr('href', '/askue/export_xls/hourly_reading?' + jQuery.param(self.filters));

        $('.js-hourly-reading-filter-field').each(function () {
            var name_field = $(this).attr('name');

            self.filters[name_field] = $(this).val();
        });

        $('.js-hourly-reading-filter-field').on('change', function () {
            var name_field = $(this).attr('name');

            self.filters[name_field] = $(this).val();

            self.filterIndication()
        })
    }

    filterIndication() {
        var self = this;

        $('.page-about-block__link-item').attr('href', '/askue/export_xls/hourly_reading?' + jQuery.param(self.filters));
        $.ajax({
            type: "GET",
            dataType: 'json',
            url: '/askue/indications_filter',
            data: self.filters,
            success: function(data, status, xhr ) {
                self.editDailyReadings(data);
                self.initGraaphics();
                self.initOpenPopup();
            },

            error: function (data) {
                console.log(data)
            }
        });
    }

    editDailyReadings(data) {
        if(data.askue_daily_readings){
            $('.askue-hourly-reading__counters-wrapper').html(data.askue_daily_readings)

        }
    }
};

export default window.AskueHourlyReadings