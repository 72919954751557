window.AskueExportXls = class AskueExportXls {
    constructor() {
        this.initExportXlsButton()
    }

    initExportXlsButton() {
        $('.js-askue-export-xls').click(function () {
            var type_export = $(this).attr('data-type');
            var filters = {
                type_export: type_export,
                date_start: $('#date_start').val(),
                date_end: $('#date_end').val(),
                counter: $('#id_counter').val()
            };

            $.ajax({
                type: "GET",
                dataType: 'xlsx',
                url: '/askue/export_xls',
                data: filters,
                success: function(data, status, xhr ) {
                    console.log(data)
                },

                error: function (data) {
                    console.log(data)
                }
            });
        })
    }
};

export default window.AskueExportXls