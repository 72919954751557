import cadesplugin from '../libs/cadesplugin_api'
import M from 'materialize-css'

var CAPICOM_CURRENT_USER_STORE = 2;
var CAPICOM_MY_STORE = "My";
var CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;


function getCertificates() {

    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(function* (args) {
            var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

            var CertificatesObj = yield oStore.Certificates;

            var certificatesCount = yield CertificatesObj.Count;

            for (var i = certificatesCount; i >= 1; i--) {
                var cert;
                try {
                    cert = yield CertificatesObj.Item(i);
                } catch (ex) {
                    alert("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
                    return;
                }
                var date = new Date();
                var validToDate = new Date((yield cert.ValidToDate));
                var validFromDate = new Date(yield cert.ValidFromDate);
                var validator = yield cert.IsValid();
                var isValid = yield validator.Result;
                if (date < validToDate && date > validFromDate && (yield cert.HasPrivateKey()) && isValid) {
                    var option = new Option(yield cert.SubjectName, i);
                    $("#signCertSelect").append(option);
                }
            }
            M.AutoInit();

        }, resolve, reject);
    });


}

export default getCertificates