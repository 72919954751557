//import M from 'materialize-css'

import M from "materialize-css";

window.CalculatePower = class CalculatePower {
    constructor() {
        var selectValue;
        var self = this;

        $('.calculator-power__check-button').click(function () {
            $('.calculator-power__check-button').removeClass('active');
            $(this).addClass('active');

            var button_id = $(this).attr('data-id');

            $($('.calculate_select_level_1')[0]).val(button_id).trigger('change');
            $($('.calculate_select_level_2')[0]).val('0_0').trigger('change');
            $($('.calculate_select_level_3')[0]).val('0_0_0').trigger('change');
            M.AutoInit();
        });

        $(".calculate_select_style_css").change(function () {
            self.ShowLevelByClassName(this);
            self.SetSelectValue(this.value);
            self.ShowInputAndResultBoxes(this.value);
            M.AutoInit();
        });

        $(".calculate-get_value").on('input', function () {
            self.Calculate(self.GetSelectValue())
        });
    }

    SetSelectValue = (value) => {
        this.selectValue = value;
    };

    GetSelectValue = () => {
        return this.selectValue;
    };

    ShowInputAndResultBoxes = (value) => {
        if (value == "1_1_1" || value == "1_1_2" || value == "1_1_3" || value == "1_1_4" || value == "1_2" ||
            value == "1_3_1" || value == "1_3_2" || value == "1_3_3" || value == "1_4" || value == "1_5" ||
            value == "1_6_1" || value == "1_6_2" || value == "1_6_3" || value == "1_7" || value == "2_1_1" || value == "2_1_2" ||
            value == "2_1_3" || value == "2_1_4" || value == "2_1_5" || value == "2_1_6" || value == "2_2_1_1" ||
            value == "2_2_1_2" || value == "2_2_2_1" || value == "2_2_2_2" || value == "2_3_1_1" || value == "2_3_1_2" ||
            value == "2_3_1_3" || value == "2_3_2_1" || value == "2_3_2_2" || value == "2_3_2_3" || value == "2_3_2_4" ||
            value == "2_4_1" || value == "2_4_2" || value == "2_5_1" || value == "2_5_2" || value == "2_5_3" ||
            value == "2_6_1" || value == "2_6_2" || value == "2_7_1" || value == "2_7_2" || value == "2_8_1" || value == "2_8_2") {
            this.ActionWithIdByName("calculator_input_value_block", "block");
            this.ActionWithIdByName("calculator_result_block", "block");
        } else {
            this.ActionWithIdByName("calculator_input_value_block", "none");
            this.ActionWithIdByName("calculator_result_block", "none");
        }

        if (value == "1_4" || value == "1_5") {
            this.ActionWithIdByName("calculator_total_load_input_block", "block");
        } else {
            this.ActionWithIdByName("calculator_total_load_input_block", "none");
        }


        if (value == "1_1_1" || value == "1_1_2" || value == "1_1_3" || value == "1_1_4" || value == "1_4"){
            $('#calculator_text_variable').html('Количество квартир (шт.)')
        }
        else if (value == "1_2"){
            $('#calculator_text_variable').html('Количество домиков (шт.)')
        }
        else if(value == "1_3_1" || value == "1_3_2" || value == "1_3_3" || value == "1_5"){
            $('#calculator_text_variable').html('Количество коттеджей (шт.)')
        }
        else if(value == "1_6_1" || value == "1_6_2" || value == "1_6_3"){
            $('#calculator_text_variable').html('Количество домов (шт.)')
        }
        else if(value == "1_7"){
            $('#calculator_text_variable').html('Количество гаражей (шт.)')
        }
        else if (value == "2_1_1" || value == "2_1_2" || value == "2_1_3" || value == "2_1_4" || value == "2_1_5"){
            $('#calculator_text_variable').html('Количество учащихся (чел.)')
        }
        else if (value == "2_1_6" || value == "2_7_1" || value == "2_8_1" || value == "2_8_2"){
            $('#calculator_text_variable').html('Количество мест (шт.)')
        }
        else if (value == "2_2_1_1" || value == "2_2_1_2" || value == "2_2_2_1" || value == "2_2_2_2"){
            $('#calculator_text_variable').html('Площадь торгового зала (м2.)')
        }
        else if (value == "2_3_1_1" || value == "2_3_1_2" || value == "2_3_1_3" || value == "2_3_2_1" || value == "2_3_2_2" || value == "2_3_2_3" || value == "2_3_2_4"){
            $('#calculator_text_variable').html('Посадочных мест (шт.)')
        }
        else if (value == "2_4_2"){
            $('#calculator_text_variable').html('Рабочих мест')
        }
        else if (value == "2_4_1"){
            $('#calculator_text_variable').html('Вес обрабатываемых вещей (кг.)')
        }
        else if (value == "2_5_1" || value == "2_5_2" || value == "2_5_3"){
            $('#calculator_text_variable').html('Число посадочных мест')
        }
        else if (value == "2_6_1" || value == "2_6_2"){
            $('#calculator_text_variable').html('Общая площадь (м2.)')
        }
        else if (value == "2_7_2"){
            $('#calculator_text_variable').html('Площадь жилых помещений (м2.)')
        }

    };

    // Метод, вызывающий нужные методы для расчета итоговой мощности и записис результата в поле
    Calculate = (selectValue) => {
        var result = 0;
        var fieldValue = $('#calculator_input_value').val().replace(/,/, '.');


        if (selectValue == '1_1_1') {
            result = this.CalculateWithInterpolate(fieldValue, 0);
        } else if (selectValue == '1_1_2') {
            result = this.CalculateWithInterpolate(fieldValue, 1);
        } else if (selectValue == '1_1_3') {
            result = this.CalculateWithInterpolate(fieldValue, 2);
        } else if (selectValue == '1_1_4') {
            result = this.CalculateWithInterpolate(fieldValue, 3);
        } else if (selectValue == '1_2') {
            result = this.CalculateWithInterpolate(fieldValue, 4);
        } else if (selectValue == '1_3_1') {
            result = this.CalculateWithInterpolate(fieldValue, 5);
        } else if (selectValue == '1_3_2') {
            result = this.CalculateWithInterpolate(fieldValue, 6);
        } else if (selectValue == '1_3_3') {
            result = this.CalculateWithInterpolate(fieldValue, 7);
        } else if (selectValue == '1_4') {
            result = this.CalculateMultiFlats(fieldValue, $('#calculator_total_load_input').val().replace(/,/, '.'), 0);
        } else if (selectValue == '1_5') {
            result = this.CalculateMultiFlats(fieldValue, $('#calculator_total_load_input').val().replace(/,/, '.'), 1);
        } else if (selectValue == '1_6_1') {
            result = fieldValue * this.GetConstantPower(0);
        } else if (selectValue == '1_6_2') {
            result = fieldValue * this.GetConstantPower(1);
        } else if (selectValue == '1_6_3') {
            result = fieldValue * this.GetConstantPower(2);
        } else if (selectValue == '1_7') {
            result = fieldValue * this.GetConstantPower(3);
        } else if (selectValue == '2_1_1') {
            result = this.CalculateNonResidential(fieldValue, 0, 0);
        } else if (selectValue == '2_1_2') {
            result = this.CalculateNonResidential(fieldValue, 0, 1);
        } else if (selectValue == '2_1_3') {
            result = this.CalculateNonResidential(fieldValue, 0, 2);
        } else if (selectValue == '2_1_4') {
            result = this.CalculateNonResidential(fieldValue, 0, 3);
        } else if (selectValue == '2_1_5') {
            result = this.CalculateNonResidential(fieldValue, 0, 4);
        } else if (selectValue == '2_1_6') {
            result = this.CalculateNonResidential(fieldValue, 0, 5);
        } else if (selectValue == '2_2_1_1') {
            result = this.CalculateNonResidential(fieldValue, 1, 0);
        } else if (selectValue == '2_2_1_2') {
            result = this.CalculateNonResidential(fieldValue, 1, 1);
        } else if (selectValue == '2_2_2_1') {
            result = this.CalculateNonResidential(fieldValue, 1, 2);
        } else if (selectValue == '2_2_2_2') {
            result = this.CalculateNonResidential(fieldValue, 1, 3);
        } else if (selectValue == '2_3_1_1') {
            result = this.CalculateNonResidential(fieldValue, 2, 0);
        } else if (selectValue == '2_3_1_2') {
            result = this.CalculateNonResidential(fieldValue, 2, 1);
        } else if (selectValue == '2_3_1_3') {
            result = this.CalculateNonResidential(fieldValue, 2, 2);
        } else if (selectValue == '2_3_2_1') {
            result = this.CalculateNonResidential(fieldValue, 2, 3);
        } else if (selectValue == '2_3_2_2') {
            result = this.CalculateNonResidential(fieldValue, 2, 4);
        } else if (selectValue == '2_3_2_3') {
            result = this.CalculateNonResidential(fieldValue, 2, 5);
        } else if (selectValue == '2_3_2_4') {
            result = this.CalculateNonResidential(fieldValue, 2, 6);
        } else if (selectValue == '2_4_1') {
            result = this.CalculateNonResidential(fieldValue, 3, 0);
        } else if (selectValue == '2_4_2') {
            result = this.CalculateNonResidential(fieldValue, 3, 1);
        } else if (selectValue == '2_5_1') {
            result = this.CalculateNonResidential(fieldValue, 4, 0);
        } else if (selectValue == '2_5_2') {
            result = this.CalculateNonResidential(fieldValue, 4, 1);
        } else if (selectValue == '2_5_3') {
            result = this.CalculateNonResidential(fieldValue, 4, 2);
        } else if (selectValue == '2_6_1') {
            result = this.CalculateNonResidential(fieldValue, 5, 0);
        } else if (selectValue == '2_6_2') {
            result = this.CalculateNonResidential(fieldValue, 5, 1);
        } else if (selectValue == '2_7_1') {
            result = this.CalculateNonResidential(fieldValue, 6, 0);
        } else if (selectValue == '2_7_2') {
            result = this.CalculateNonResidential(fieldValue, 6, 1);
        } else if (selectValue == '2_8_1') {
            result = this.CalculateNonResidential(fieldValue, 7, 0);
        } else if (selectValue == '2_8_2') {
            result = this.CalculateNonResidential(fieldValue, 7, 1);
        }

        $("#calculate-result").html(parseFloat(result, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ").toString())
    };

// Метод, возвращающий 2 выбранных элемента из двумерного массива
    GetElementFromPriceArray = (position, price) => {
        // Задаются постоянные значения для расчета мощности. Где строка массива - это расчетные данные для данной категории.
        // Столбец - выбранное значение, в зависимости от количества квартир/домов
        var priceArray = [
            [4.5, 2.8, 2.3, 2, 1.8, 1.65, 1.4, 1.2, 1.05, 0.85, 0.77, 0.71, 0.69, 0.67],
            [6, 3.4, 2.9, 2.5, 2.2, 2, 1.8, 1.4, 1.3, 1.08, 1, 0.92, 0.84, 0.76],
            [10, 5.1, 3.8, 3.2, 2.8, 2.6, 2.2, 1.95, 1.7, 1.5, 1.36, 1.27, 1.23, 1.19],
            [14, 8.1, 6.7, 5.9, 5.3, 4.9, 4.2, 3.3, 2.8, 1.95, 1.83, 1.72, 1.67, 1.62],
            [4, 2.3, 1.7, 1.4, 1.2, 1.1, 0.9, 0.76, 0.69, 0.61, 0.58, 0.54, 0.51, 0.46],
            [11.5, 6.5, 5.4, 4.7, 4.3, 3.9, 3.3, 2.6, 2.1, 2, 1.9, 1.8, 1.7, 1.6],
            [22.3, 13.3, 11.3, 10, 9.3, 8.6, 7.5, 6.3, 5.6, 5, 4.4, 3.8, 3.2, 2.6],
            [14.5, 8.6, 7.2, 6.5, 5.8, 5.5, 4.7, 3.9, 3.3, 2.6, 1.9, 1.2, 0.5, 0.2]];

        return priceArray[position][price];
    };

// Метод, возвращающий 2 выбранных элемента из одномерного массива
    GetElementsFromFlatsArray = (flat) => {
        // Массив содержит значения количество квартир, для которых не требуется интерполяция
        var flatsArray = [5, 6, 9, 12, 15, 18, 24, 40, 60, 100, 200, 400, 600, 1000];

        return flatsArray[flat];
    };

    // Метод, возвращающий статичный результат мощности
    GetConstantPower = (power) => {
        // Массив содержит значения количество квартир, для которых не требуется интерполяция
        var powerArray = [4.390, 12.890, 14.890, 3.600];

        return powerArray[power];
    };

// Метод, возвращающий нужный элемент массива
    GetOnePrice = (level, x) => {
        return this.GetElementFromPriceArray(level, this.GetPosition(x));
    };

// Метод, возвращающий позицию элемента Х из массива
    GetPosition = (x) => {
        var result;

        var position = {
            value_6: 1,
            value_9: 2,
            value_12: 3,
            value_15: 4,
            value_18: 5,
            value_24: 6,
            value_40: 7,
            value_60: 8,
            value_100: 9,
            value_200: 10,
            value_400: 11,
            value_600: 12,
            value_1000: 13
        };

        if (x >= 6 && x <= 1000) {
            result = position['value_' + x];
        } else if (x >= 1 && x <= 5) {
            result = 0;
        }

        return result;
    };

// Метод, возвращающий 4 элемента для интерполяции
    GetFlatsAndPriceRange = (pricePosition, flatsNumber) => {
        var x = flatsNumber;
        var position1, position2;

        if (x > 6 && x < 9) {
            position1 = 1;
            position2 = 2;
        } else if (x > 9 && x < 12) {
            position1 = 2;
            position2 = 3;
        } else if (x > 12 && x < 15) {
            position1 = 3;
            position2 = 4;
        } else if (x > 15 && x < 18) {
            position1 = 4;
            position2 = 5;
        } else if (x > 18 && x < 24) {
            position1 = 5;
            position2 = 6;
        } else if (x > 24 && x < 40) {
            position1 = 6;
            position2 = 7;
        } else if (x > 40 && x < 60) {
            position1 = 7;
            position2 = 8;
        } else if (x > 60 && x < 100) {
            position1 = 8;
            position2 = 9;
        } else if (x > 100 && x < 200) {
            position1 = 9;
            position2 = 10;
        } else if (x > 200 && x < 400) {
            position1 = 10;
            position2 = 11;
        } else if (x > 400 && x < 600) {
            position1 = 11;
            position2 = 12;
        } else if (x > 600 && x < 1000) {
            position1 = 12;
            position2 = 13;
        } else if (x >= 1000) {
            position1 = 13;
            position2 = 13;
        }

        var flatsResult = [this.GetElementsFromFlatsArray(position1), this.GetElementsFromFlatsArray(position2)];
        var priceResult = [this.GetElementFromPriceArray(pricePosition, position1), this.GetElementFromPriceArray(pricePosition, position2)];

        var result = [flatsResult[0], flatsResult[1], priceResult[0], priceResult[1]];

        return result;
    };

// Метод, возвращающий результат расчета потребления мощности
    CalculateWithInterpolate = (inc_x, inc_level) => {
        var x = parseFloat(inc_x);
        var level = parseInt(inc_level);

        var result = 0;

        // Если значение равно заданному, то делаем простой расчет. Иначе, делаем интерполяцию из значений,
        // которые находятся в пределах этого значения
        if ((x >= 1 && x <= 5) || x == 6 || x == 9 || x == 12 || x == 15 || x == 18 || x == 24 ||
            x == 40 || x == 60 || x == 100 || x == 200 || x == 400 || x == 600 || x == 1000 || x == 6) {
            result = x * this.GetOnePrice(level, x);
        } else {
            var rangeOfValue = this.GetFlatsAndPriceRange(level, x);

            if (rangeOfValue[0] == rangeOfValue[1] || rangeOfValue[2] == rangeOfValue[3]) {
                result = x * rangeOfValue[2];
            }
            else {
                result = x * this.Interpolate(rangeOfValue[0], rangeOfValue[1], rangeOfValue[2], rangeOfValue[3], x);
            }
        }

        if (isNaN(result)) {
            result = 0;
        }

        return result;
    };

// Метод, возвращающий результат интерполяции
    Interpolate = (inc_X_1, inc_X_2, inc_F_X_1, inc_F_X_2, inc_x) => {
        var x1 = parseFloat(inc_X_1);
        var x2 = parseFloat(inc_X_2);
        var fX1 = parseFloat(inc_F_X_1);
        var fX2 = parseFloat(inc_F_X_2);
        var x = parseFloat(inc_x);

        var result;

        result = fX1 + (fX2 - fX1) * (x - x1) / (x2 - x1);

        return result;
    };

//Метод, возвращающий коэффицент из двумерного массива
    GetCoefficientMultiFlats = (row, column) => {
        var coefficientArray = [[0.8, 0.65, 0.6, 0.55, 0.5, 0.48, 0.45],
            [0.8, 0.65, 0.6, 0.55, 0.5, 0.48, 0.45]];

        return coefficientArray[row][column];
    };

//Метод, возвращающий результат расчета мощности
    CalculateMultiFlats = (firstFieldValue, secondFieldValue, row) => {
        var result = 0;

        var x = firstFieldValue * secondFieldValue;

        if (x <= 17) {
            result = x * this.GetCoefficientMultiFlats(row, 0);
        } else if (x > 17 && x <= 25) {
            result = x * this.GetCoefficientMultiFlats(row, 1);
        } else if (x > 25 && x <= 35) {
            result = x * this.GetCoefficientMultiFlats(row, 2);
        } else if (x > 35 && x <= 45) {
            result = x * this.GetCoefficientMultiFlats(row, 3);
        } else if (x > 45 && x <= 55) {
            result = x * this.GetCoefficientMultiFlats(row, 4);
        } else if (x > 55 && x <= 65) {
            result = x * this.GetCoefficientMultiFlats(row, 5);
        } else if (x > 65) {
            result = x * this.GetCoefficientMultiFlats(row, 6);
        }

        return result;
    };

//Метод, возвращающий значение удельной нагрузки
    GetUnitLoad = (row, column) => {
        var unitLoadArray = [
            [0.25, 0.17, 0.17, 0.15, 0.46, 0.46],
            [0.23, 0.25, 0.14, 0.16],
            [1.04, 0.86, 0.75, 0.9, 0.81, 0.69, 0.56],
            [0.075, 1.5],
            [0.12, 0.14, 0.46],
            [0.043, 0.054],
            [0.36, 0.023],
            [0.34, 0.46]];

        return unitLoadArray[row][column];
    };

//Метод, возвращающий расчетный коэффициент
    GetCoefficient = (row, column) => {
        var coefficientArray = [
            [0.95, 0.92, 0.92, 0.92, 0.86, 0.97],
            [0.82, 0.8, 0.92, 0.9],
            [0.98, 0.98, 0.98, 0.95, 0.95, 0.95, 0.95],
            [0.8, 0.97],
            [0.95, 0.92, 0.92],
            [0.9, 0.87],
            [0.92, 0.92],
            [0.9, 0.85]];

        return coefficientArray[row][column];
    };

//Метод, возвращающий результат перемножения удельной мощности и коэффициента
    CalculateNonResidential = (value, row, column) => {

        return value * this.GetUnitLoad(row, column) * this.GetCoefficient(row, column);
    };


// Метод, делающий действия по имени класса
    ActionWithClassByName = (name, action) => {
        var classForAction = $("." + name);

        switch (action) {
            case 'hide':
                classForAction.css("display", "none");
                break;

            case 'default':
                classForAction.prop('selectedIndex', 0);
                //M.autoinit();
                break;

            case 'empty':
                classForAction.val("");
                break;
        }
    };

// Метод, делающий действия по id атрибута
    ActionWithIdByName = (name, action) => {
        var idForAction = $("#" + name);

        switch (action) {
            case 'block':
                idForAction.css("display", "block");
                break;

            case 'none':
                idForAction.css("display", "none");
                break;
        }
    };

// Метод, вызывающий методы для сокрытия классов с 2 по 5
    HideClassByLevelFrom_2_To_5 = () => {
        this.ActionWithClassByName("calculator_level_of_select_block_2", "hide");
        this.ActionWithClassByName("calculate_select_level_2", "default");
        this.HideClassByLevelFrom_3_To_5();
    };

// Метод, вызывающий методы для сокрытия классов с 3 по 5
    HideClassByLevelFrom_3_To_5 = () => {
        this.ActionWithClassByName("calculator_level_of_select_block_3", "hide");
        this.ActionWithClassByName("calculate_select_level_3", "default");
        this.HideClassByLevelFrom_4_To_5();
    };

// Метод, вызывающий методы для сокрытия классов с 4 по 5
    HideClassByLevelFrom_4_To_5 = () => {
        this.ActionWithClassByName("calculator_level_of_select_block_4", "hide");
        this.ActionWithClassByName("calculate_select_level_4", "default");
        this.HideClassByLevelFrom_5_To_5();
    };

// Метод, вызывающий методы для сокрытия классов с 5 по 5
    HideClassByLevelFrom_5_To_5 = () => {
        this.ActionWithClassByName("calculator_level_of_select_block_5", "hide");
        this.ActionWithClassByName("calculate-get_value", "empty");
    };

// Метод, делающий видимым элемент по id и отчищающий поле результата
    ShowElementById = (id) => {
        this.ActionWithIdByName(id, "block");
        $('#calculate-result').html("0.000");
    };

//Метод, вызывающий методы для сокрытия и отображения нужных блоков
    ShowLevelByClassName = (a) => {

        switch (a.value.length) {
            case 1:
                this.HideClassByLevelFrom_2_To_5();
                this.ShowElementById("calculator_select_menu_" + a.value);
                break;

            case 3:
                this.HideClassByLevelFrom_3_To_5();
                this.ShowElementById("calculator_select_menu_" + a.value);
                break;

            case 5:
                this.HideClassByLevelFrom_4_To_5();
                this.ShowElementById("calculator_select_menu_" + a.value);
                break;

            case 7:
                this.HideClassByLevelFrom_5_To_5();
                this.ShowElementById("calculator_select_menu_" + a.value);
                break;
        }
    }
};

export default window.CalculatePower