window.PlannedShutdownFilter = class PlannedShutdownFilter {
    constructor() {
        var pagePathArray = window.location.pathname.split('/');

        var filters = {
            city_id: pagePathArray[3],
            date_start: null,
            date_end: null,
            address: null,
            work_type: null
        };

        var self = this;
        $('.js-planned-shutdown-filter-field').on('change', function () {
            var name_field = $(this).attr('name');

            filters[name_field] = $(this).val();

            if(filters[name_field] == 'plan'){
                $('.planned-shutdown__list-header-column--planned').show();
                $('.planned-shutdown__list-header-column--dont-planned').hide();
            }
            else{
                $('.planned-shutdown__list-header-column--planned').hide();
                $('.planned-shutdown__list-header-column--dont-planned').show();
            }

            self.filteredPlanedShutdowns(filters)
        })
    }

    filteredPlanedShutdowns(filters) {
        $.ajax({
            type: "GET",
            dataType: 'json',
            url: '/cabinet/planned_shutdown/filters',
            data: filters,
            success: function(data, status, xhr ) {
                $('.planned-shutdown__list-content').html('');
                $('.planned-shutdown__list-content').html(data.planned_shutdowns);
                if(data.planned_shutdowns){
                    if(data.planned_shutdowns.lenth >0){
                        $('.planned-shutdown__list-header').prop('disable', false);
                        $('.planned-shutdown__list-null-result').prop('disable', false)
                    }
                    else{
                        $('.planned-shutdown__list-header').prop('disable', true);
                        $('.planned-shutdown__list-null-result').prop('disable', true)
                    }
                }
                else{
                    $('.planned-shutdown__list-header').prop('disable', true);
                    $('.planned-shutdown__list-null-result').prop('disable', true)
                }
            },

            error: function (data) {
                console.log(data)
            }
        });
    }
};

export default window.PlannedShutdownFilter