import M from "materialize-css"
import getCertificates from "../app/get_certificates"
import InputMask from "inputmask"

export default class NewRequestForm {
  constructor(actionType) {
    this.actionType = actionType
    this.cryptoProInit = false
    this.initCategorySelectButtons()
    this.initStageConnectionBlock()
    this.initMaxPowerCalculation()
    this.calculateStageNumbers()
    this.initNavButtons()
    this.initReasonSelect()
    this.initDocObtainingSelects()
    this.calculateMaxPower()
    this.autoChangeVoltage()
    this.initAutoChangeVoltage()
    this.displayFormerOwnerFields()
    this.initReissuanceSecondStage()
    this.selectDefaultCategoeyInBlocks()
    this.handleShowNewRepresentativeFields()
    this.initHandleShowNewRepresentativeFields()
    this.handleRepresentativeDocumentsDisplay()
    this.initHandleRepresentativeDocumentsDisplay()
    this.handleDocumentsRemoveInvalid()
    this.handleTempConnectionFieldsDisplay()
    this.initHandleTempConnectionFieldsDisplay()
    this.initHandleExistingContractDocumentsDisplay()
    this.handleExistingContractDocumentsDisplay()
    this.initContractSupplier()
    this.initConnectionObject()
    this.initNatureLoad()
    this.initCommercialApprovalFields()
    this.initHeaderRequestStatusesNotifications()
    this.disableStandardTypeSelect()
    this.hideEmptyDocsContainers()
    this.hideDeletedFile()
    this.initRequestFeedbackDescription()
  }

  initCategorySelectButtons() {
    $(".stage-connections-container").each(function () {
      $(this)
        .find(".stage-connection")
        .each(function (index) {
          index += 1
          if (index < 10) {
            index = "0" + index
          }
          $(this).find(".stage-connection__index").text(index)
          var currentSelection = $(this)
            .find(".stage-connection__category select")
            .prop("selectedIndex")
          if (currentSelection != undefined) {
            $(this)
              .find(".connection-category-select__option")
              .eq(currentSelection)
              .addClass("active")
          }
        })
    })

    $("body").on(
      "click",
      ".stage-connection__field-group:not(.show-only) .connection-category-select__option",
      function () {
        $(this)
          .parent()
          .find(".connection-category-select__option.active")
          .removeClass("active")
        $(this).addClass("active")
        $(this)
          .closest(".stage-connection")
          .find(
            ".request_request_connection_power_receivers_category ul.dropdown-content.select-dropdown li.selected"
          )
          .removeClass("selected")
        var selectElement = $(this)
          .closest(".stage-connection")
          .find(".stage-connection__category select")
        var selectOption = selectElement
          .find("option")
          .eq($(this).attr("data-index"))
        selectElement.val(selectOption.val())
      }
    )
  }

  initStageConnectionBlock() {
    var indexCurrent = 0
    $(".add_fields").on("click", function (e) {
      var index
      if (indexCurrent >= 20) {
        e.preventDefault()
        e.stopPropagation()
      }

      if (indexCurrent >= 19) {
        $($(this).parents(".form-inputs")[0]).hide()
      }

      indexCurrent =
        $(this)
          .closest(".stage-connections-container")
          .find(".stage-connection").length + 1
      if (indexCurrent < 10) {
        index = "0" + indexCurrent
      } else {
        index = indexCurrent
      }

      setTimeout(
        function () {
          var container = $(this).closest(".stage-connections-container")
          var item = container.find(".stage-connection").last()

          var inputVoltageGlobal = $(
            ".request_request_connection_voltage_power_receiver"
          )
          var voltageSelect = item.find(
            ".request_request_connection_power_receivers_voltage"
          )
          var inputCategoryGlobal = $(
            ".request_request_connection_category_of_reliability"
          )
          var categorySelectButtons = item.find(".connection-category-select")
          var categorySelect = item.find(
            ".request_request_connection_power_receivers_category select"
          )

          if (inputVoltageGlobal.find("select").val()) {
            voltageSelect
              .find("select")
              .val(inputVoltageGlobal.find("select").val())
            voltageSelect
              .find("input")
              .val(inputVoltageGlobal.find("select option:checked").text())
            var selectedIndex = inputVoltageGlobal
              .find(".dropdown-content.select-dropdown li.selected")
              .index()
            voltageSelect
              .find("ul.dropdown-content.select-dropdown li.selected")
              .removeClass("selected")
            voltageSelect
              .find("ul.dropdown-content.select-dropdown li")
              .eq(selectedIndex)
              .addClass("selected")
          }

          if (inputCategoryGlobal.find("select").val()) {
            selectedIndex = inputCategoryGlobal
              .find(".dropdown-content.select-dropdown li.selected")
              .index()
            categorySelectButtons
              .find(".connection-category-select__option.active")
              .removeClass("active")
            categorySelectButtons
              .find(".connection-category-select__option")
              .eq(selectedIndex)
              .addClass("active")
            categorySelect.val(
              inputCategoryGlobal.find("select").val() + "_category"
            )
          }

          container.find(".stage-connection__index").last().text(index)
          container
            .find(".stage-connection .js-conn-stage-number")
            .last()
            .val(parseInt(index) - 1)

          window.baseFormObject.initDatePickers()

          var dateMask = new InputMask("99-99-9999")
          dateMask.mask($(".js-datepicker"))

          M.AutoInit()
        }.bind($(this)),
        2
      )
    })

    $("body").on("click", ".stage-connection__delete_button", function () {
      this.calculateStageNumbers

      $(this).closest(".stage-connection").remove()

      $(".stage-connections-container").each(function () {
        $(this)
          .find(".stage-connection")
          .each(function (connStageIndex) {
            var index
            connStageIndex += 1
            if (connStageIndex < 10) {
              index = "0" + connStageIndex
            } else {
              index = connStageIndex
            }

            if (connStageIndex < 20) {
              $(".js-add-connection-object").parents(".form-inputs").show()
            }

            $(this).find(".stage-connection__index").text(index)
          })
      })
    })
  }

  initMaxPowerCalculation() {
    if ($(".max-power__val").length) {
      this.calculateMaxPower()
    }

    $("#request_request_connection_max_power_previously_attached").on(
      "input",
      this.calculateMaxPower
    )
    $("#request_request_connection_max_power_optional_attachable").on(
      "input",
      this.calculateMaxPower
    )
    $(".request_request_connection_category_of_reliability select").on(
      "change",
      this.calculateMaxPower
    )
  }

  calculateStageNumbers() {
    $(".js-connection-objects").each(function () {
      $(this)
        .children(".stage-connection")
        .each(function (index) {
          $(this).find(".js-conn-stage-number").val(index)
        })
    })
  }

  calculateMaxPower() {
    if (
      $("#request_request_connection_max_power_optional_attachable").length &&
      $("#request_request_connection_max_power_previously_attached").length
    ) {
      var optionalPower = $(
        "#request_request_connection_max_power_optional_attachable"
      )
        .val()
        .replace(",", ".")
      var attachedPower = $(
        "#request_request_connection_max_power_previously_attached"
      )
        .val()
        .replace(",", ".")
      var category = $(
        "#request_request_connection_category_of_reliability"
      ).val()
      var reason = $("#request_request_connection_reason").length
        ? $("#request_request_connection_reason").val()
        : ""
      var maxPowerContainer = $(".max-power__val")
      var maxPowerCurrent = 0

      if (
        !isNaN(optionalPower) &&
        optionalPower.length &&
        !isNaN(attachedPower) &&
        attachedPower.length &&
        !reason.includes("changing_category_reliability")
      ) {
        maxPowerCurrent = parseFloat(attachedPower) + parseFloat(optionalPower)
        maxPowerContainer.text(+maxPowerCurrent.toFixed(4))
      } else if (
        !isNaN(attachedPower) &&
        attachedPower.length &&
        !reason.includes("changing_category_reliability")
      ) {
        maxPowerCurrent = parseFloat(attachedPower)
        maxPowerContainer.text(+maxPowerCurrent.toFixed(4))
      } else if (!isNaN(optionalPower) && optionalPower.length) {
        maxPowerCurrent = parseFloat(optionalPower)
        maxPowerContainer.text(+maxPowerCurrent.toFixed(4))
      } else {
        maxPowerCurrent = 0
        maxPowerContainer.text(0)
      }

      if (parseFloat(maxPowerContainer.text()) > 150) {
        $(".js-request-email").removeAttr("required")
        $(".request-standard__email-field-wrap label").removeClass("required")
        $(".request-standard__email-field-wrap label abbr").hide()
      } else {
        $(".js-request-email").attr("required", "required")
        $(".request-standard__email-field-wrap label").addClass("required")
        $(".request-standard__email-field-wrap label abbr").show()
      }

      if (parseFloat(maxPowerContainer.text()) <= 15 && category == "third") {
        if ($(".js-individual-behavior").length) {
          $(".js-individual-behavior").hide()
          $(".js-power-receivers.js-individual-behavior")
            .find("input")
            .prop("disabled", "disabled")
          $(".js-power-receivers.js-individual-behavior")
            .find("select")
            .prop("disabled", "disabled")
          $(".js-power-receivers.js-individual-behavior")
            .siblings("h5, h4")
            .hide()
        }
      } else {
        if ($(".js-individual-behavior").length) {
          $(".js-power-receivers").find("input").prop("disabled", false)
          $(".js-power-receivers").find("select").prop("disabled", false)
          $(".js-power-receivers").show()
          $(".js-power-receivers").siblings("h5, h4").show()
          $(".js-individual-behavior").show()
        }
      }
      var value_category = $(
        ".request_request_connection_category_of_reliability select"
      ).val()
      if (value_category) {
        if (value_category && value_category.includes("first")) {
          $(".js-first-category-only").show()
          $(".js-first-category-only input").removeAttr("disabled")
        } else {
          $(".js-first-category-only").hide()
          $(".js-first-category-only input").attr("disabled", "disabled")
        }

        if ($(".js-legal-person-behavior").length) {
          if (
            value_category &&
            (value_category.includes("first") ||
              value_category.includes("second")) &&
            parseFloat(maxPowerContainer.text()) > 150
          ) {
            $(".js-first-category-only").show()
            $(".js-first-category-only input").removeAttr("disabled")
          } else {
            $(".js-first-category-only").hide()
            $(".js-first-category-only input").attr("disabled", "disabled")
          }

          if (
            (value_category &&
              (value_category.includes("first") ||
                value_category.includes("second"))) ||
            (parseFloat(maxPowerContainer.text()) > 15 &&
              parseFloat(maxPowerContainer.text()) <= 150 &&
              value_category.includes("third"))
          ) {
            $(".js-first-second-category-only").show()
            $(".js-first-second-category-only input").removeAttr("disabled")
          } else {
            $(".js-first-second-category-only").hide()
            $(".js-first-second-category-only input").attr(
              "disabled",
              "disabled"
            )
          }

          if (value_category && value_category.includes("first")) {
            $(".js-first-category-only").show()
            $(".js-first-category-only input").removeAttr("disabled")
          }

          if (
            parseFloat(maxPowerContainer.text()) >= 150 &&
            parseFloat(maxPowerContainer.text()) <= 670
          ) {
            $(".js-first-category-only, .js-first-second-category-only").hide()
            $(
              ".js-first-category-only input, .js-first-second-category-only input"
            ).attr("disabled", "disabled")
          }

          if (parseFloat(maxPowerContainer.text()) > 670) {
            $(".js-first-category-only, .js-first-second-category-only").show()
            $(
              ".js-first-category-only input, .js-first-second-category-only input"
            ).removeAttr("disabled")
          }
        }

        if (value_category && value_category.includes("first")) {
          $(".js-first-category-disable").hide()
          $(".js-first-category-disable input").attr("disabled", "disabled")
        } else {
          $(".js-first-category-disable").show()
          $(".js-first-category-disable input").removeAttr("disabled")
        }
      }

      var select = $(".js-kenk-documents-obtaining-method").find(
        ".dropdown-content li"
      )
      if (maxPowerCurrent < 150) {
        select.each(function () {
          if ($(this).text() !== "В личном кабинете") {
            $(this).hide()
          }
        })
      } else {
        select.each(function () {
          $(this).show()
        })
      }

      if (maxPowerCurrent <= 150 && maxPowerCurrent > 15) {
        $(".js-payment-variant-block").show()
      } else {
        $(".js-payment-variant-block").hide()
      }
      if(maxPowerCurrent <= 150){
        var data = $('#title_doc_type_power_recever_place_plan').text().replace('*','')
        $('#title_doc_type_power_recever_place_plan').html(data)
      }else{
        $('#title_doc_type_power_recever_place_plan').append('*')
      }

      if (maxPowerCurrent > 150 && !this.cryptoProInit) {
        if ($("#signCertSelect").length > 0) {
          getCertificates()
          this.cryptoProInit = true
        }
      }
    }
  }

  initNavButtons() {
    $(".open-popup-button").click(function (e) {
      $("#sms-request-confirm").show()
      $(".popup-background").show()
    })

    $(".social_assistance_info_popup-open").click(function (e) {
      $(".social_assistance_info_popup").show()
      $(".popup-background").show()
    })

    $(".popup-background").click(function () {
      $("#sms-request-confirm").hide()
      $("#sms-request-error").hide()
      $("#check_request_to_kenk_popup").hide()
      $(".popup-background").hide()
      $(".success-popup").hide()
      $(".social_assistance_info_popup").hide()
    })
    $(".sms-popup__close").click(function () {
      $("#sms-request-confirm").hide()
      $("#check_request_to_kenk_popup").hide()
      $(".popup-background").hide()
    })
    $(".success-popup__close").click(function () {
      $(".success-popup").hide()
      $(".popup-background").hide()
    })
    $(".social_assistance_info_popup__close").click(function () {
      $(".social_assistance_info_popup").hide()
      $(".popup-background").hide()
    })
    $(".next-step-button").click(function () {
      var index = $(this)
        .closest(".base-tab-content")
        .attr("data-tab-content-index")
      index = parseInt(index) + 1
      $(".base-tab-content.is-visible").removeClass("is-visible")
      $(".base-tab-button.base-tab_checked").removeClass("base-tab_checked")
      $(".base-tab-content[data-tab-content-index=" + index + "]").addClass(
        "is-visible"
      )
      $(".base-tab-button[data-index=" + index + "]").addClass(
        "base-tab_checked"
      )
      $("html, body").animate(
        {
          scrollTop: 0
        },
        "fast"
      )
    })
  }

  initDocObtainingSelects() {
    if (
      $(".js-kenk-documents-obtaining-method").length &&
      $(".js-kenk-documents-obtaining-office").length
    ) {
      if (
        $(".js-kenk-documents-obtaining-method select").val() !=
        "service_center"
      ) {
        $(".js-kenk-documents-obtaining-office").hide()
        $(".js-kenk-documents-confirm-address").hide()
      }
      $(".js-kenk-documents-obtaining-method select").change(function () {
        let selected = $(this).val()
        if (selected == "service_center") {
          $(".js-kenk-documents-obtaining-office").show()
          $(".js-kenk-documents-confirm-address").hide()
        } else if (selected == "post_office") {
          $(".js-kenk-documents-confirm-address").show()
          $(".js-kenk-documents-obtaining-office").hide()
        } else {
          $(".js-kenk-documents-obtaining-office").hide()
          $(".js-kenk-documents-confirm-address").hide()
        }
      })
    }
  }

  initReasonSelect() {
    if (
      $(
        '.request_request_connection_reason li.selected:contains("Впервые вводимые в эксплуатацию ЭПУ")'
      ).length
    ) {
      $(".request_request_connection_reason li:not(.selected):not(:last-child)")
        .addClass("disabled")
        .prop("disabled", "disabled")
      $("#request_request_connection_max_power_previously_attached").val(0)
      $("#request_request_connection_max_power_previously_attached").prop(
        "disabled",
        "disabled"
      )
      $(
        ".request_request_connection_max_power_previously_attached label"
      ).addClass("active")
      $(".js-previous-receivers").show()
    }

    $(".request_request_connection_reason li").click(function () {
      if (
        $(this).hasClass("selected") &&
        $(this).find("span label span").text() ==
          "Впервые вводимые в эксплуатацию ЭПУ"
      ) {
        $(this)
          .siblings("li.selected")
          .find('[type="checkbox"]')
          .each(function () {
            $(this)[0].checked = false
          })
        $(this).siblings("li.selected").removeClass("selected")
        $(this).siblings("li").addClass("disabled")
        $(this).siblings("li input[type=checkbox]").prop("disabled", "disabled")
        $(this)
          .closest(".request_request_connection_reason")
          .find("input.select-dropdown.dropdown-trigger")
          .val("Впервые вводимые в эксплуатацию ЭПУ")
        $(this)
          .closest(".input-field.base-input.base-select")
          .find("select")
          .val(["first_commissioned"])

        $("#request_request_connection_max_power_previously_attached").val(0)
        $("#request_request_connection_max_power_previously_attached").prop(
          "disabled",
          "disabled"
        )
        $(
          ".request_request_connection_max_power_previously_attached label"
        ).addClass("active")

        $(".js-previous-receivers").hide()
      } else if (
        $(this).is(':not(".selected")') &&
        $(this).find("span label span").text() ==
          "Впервые вводимые в эксплуатацию ЭПУ"
      ) {
        $(this).siblings("li").removeClass("disabled")
        $(this).siblings("li input[type=checkbox]").prop("disabled", false)
        $("#request_request_connection_max_power_previously_attached").prop(
          "disabled",
          false
        )
        $(".js-previous-receivers").show()
      }

      if ($('[class*="_reason"] li.selected:contains("Иное")').length) {
        $("[class*='_reason_comment']").show()
      } else {
        $("[class*='_reason_comment']").hide()
      }
    })

    if ($("select#request_request_connection_get_documents_type").length) {
      if (
        $("select#request_request_connection_get_documents_type")
          .val()
          .includes("other")
      ) {
        $(".request_request_connection_reason_comment").show()
      } else {
        $(".request_request_connection_reason_comment").hide()
      }

      $("select#request_request_connection_get_documents_type").change(
        function () {
          if ($(this).val().includes("other")) {
            $(".request_request_connection_reason_comment").show()
          } else {
            $(".request_request_connection_reason_comment").hide()
          }
        }
      )

      if (
        $("select#request_request_connection_get_documents_type")
          .val()
          .includes("reissuance_documents_connection_with_change_ownership")
      ) {
        $(
          ".base-form__section-wrapper[data-reason-check='reissuance_documents_connection_with_change_ownership']"
        ).show()
      } else {
        $(
          ".base-form__section-wrapper[data-reason-check='reissuance_documents_connection_with_change_ownership']"
        ).hide()
      }

      $("select#request_request_connection_get_documents_type").change(
        function () {
          if (
            $(this)
              .val()
              .includes("reissuance_documents_connection_with_change_ownership")
          ) {
            $(
              ".base-form__section-wrapper[data-reason-check='reissuance_documents_connection_with_change_ownership']"
            ).show()
          } else {
            $(
              ".base-form__section-wrapper[data-reason-check='reissuance_documents_connection_with_change_ownership']"
            ).hide()
          }
        }
      )
    }
  }

  initContractSupplier() {
    if (
      $('[class*="_contract_supplier_supplier"] li.selected:contains("Иное")')
        .length
    ) {
      $("[class*='_contract_supplier_supplier_other_name']").show()
    } else {
      $("[class*='_contract_supplier_supplier_other_name']").hide()
    }

    $("[class*='_contract_supplier_supplier'] li").click(function () {
      if (
        $('[class*="_contract_supplier_supplier"] li.selected:contains("Иное")')
          .length
      ) {
        $("[class*='_contract_supplier_supplier_other_name']").show()
      } else {
        $("[class*='_contract_supplier_supplier_other_name']").hide()
      }
    })
  }

  initConnectionObject() {
    if ($('[class*="_name_epu"] li.selected:contains("Иное")').length) {
      $("[class*='_epu_other_name']").show()
    } else {
      $("[class*='_epu_other_name']").hide()
    }

    $("[class*='_name_epu'] li").click(function () {
      if ($('[class*="_name_epu"] li.selected:contains("Иное")').length) {
        $("[class*='_epu_other_name']").show()
      } else {
        $("[class*='_epu_other_name']").hide()
      }
    })
  }

  initNatureLoad() {
    if ($('[class*="_nature_load"] li.selected:contains("Иное")').length) {
      $("[class*='_nature_load_other_name']").show()
    } else {
      $("[class*='_nature_load_other_name']").hide()
    }

    $("[class*='_nature_load'] li").click(function () {
      if ($('[class*="_nature_load"] li.selected:contains("Иное")').length) {
        $("[class*='_nature_load_other_name']").show()
      } else {
        $("[class*='_nature_load_other_name']").hide()
      }
    })
  }

  initAutoChangeVoltage() {
    $("#request_request_connection_voltage_power_receiver").change(
      this.autoChangeVoltage
    )
  }

  autoChangeVoltage() {
    var inputVoltageGlobal = $(
      ".request_request_connection_voltage_power_receiver"
    )
    var voltageSelect = $(".request_request_connection_power_receivers_voltage")
    if (inputVoltageGlobal.find("select").val()) {
      voltageSelect.each(function () {
        $(this).find("select").val(inputVoltageGlobal.find("select").val())
        $(this)
          .find("input")
          .val(inputVoltageGlobal.find("select option:checked").text())
        var index = inputVoltageGlobal
          .find(".dropdown-content.select-dropdown li.selected")
          .index()
        $(this)
          .find("ul.dropdown-content.select-dropdown li.selected")
          .removeClass("selected")
        $(this)
          .find("ul.dropdown-content.select-dropdown li")
          .eq(index)
          .addClass("selected")
      })
    }
  }

  displayFormerOwnerFields() {
    var ownerType = $(
      "select#request_request_connection_former_owner_attributes_type_owner"
    ).val()
    var physicalPersonFields = $(".js-former-owner-fields").children(
      ".base-input:not(.request_request_connection_former_owner_organisation_name)"
    )
    var legalFields = $(
      ".js-former-owner-fields .request_request_connection_former_owner_organisation_name"
    )
    switch (ownerType) {
      case "physical_person":
        physicalPersonFields.show()
        legalFields.hide()
        break
      case "legal_person":
        physicalPersonFields.hide()
        legalFields.show()
        legalFields.children("label").text("Наименование организации")
        break
      case "ip":
        physicalPersonFields.hide()
        legalFields.show()
        legalFields.children("label").text("Наименование ИП")
        break
    }
  }

  initReissuanceSecondStage() {
    $(
      "select#request_request_connection_former_owner_attributes_type_owner"
    ).change(this.displayFormerOwnerFields)
  }

  selectDefaultCategoeyInBlocks() {
    $(".connection-category-select").each(function () {
      var el
      if (
        $(this)
          .closest(".stage-connections-container")
          .is(".js-power-receivers")
      ) {
        el = $(this)
          .closest(".stage-connection__field-group")
          .siblings(".request_request_connection_power_receivers_category")
          .find("select")
      } else {
        el = $(this)
          .closest(".stage-connection__field-group")
          .siblings(".connection_object_stage_connections_category")
          .find("select")
      }
      var index = el.prop("selectedIndex")
      if (index >= 0) {
        $(this)
          .find(".connection-category-select__option")
          .eq(index)
          .addClass("active")
      }
    })
  }

  initHandleShowNewRepresentativeFields() {
    $("body").on(
      "change",
      "#has_representative",
      this.handleShowNewRepresentativeFields
    )
    $("body").on(
      "change",
      ".js-representative-form-select select",
      this.handleShowNewRepresentativeFields
    )
  }

  handleShowNewRepresentativeFields() {
    var representativeSelect = $(".js-representative-form-select select")
    var newRepFields = $(".js-new-representative-fields")
    var hasRepresentative = $("input#has_representative").is(":checked")
    if (!representativeSelect.length) {
      if (hasRepresentative) {
        newRepFields.show()
      } else {
        newRepFields.hide()
      }
    } else if (representativeSelect.val() == "new") {
      if (hasRepresentative) {
        newRepFields.find("input").removeAttr("disabled")
      } else {
        newRepFields.find("input").attr("disabled", "disabled")
      }
      newRepFields.show()
    } else {
      newRepFields.find("input").attr("disabled", "disabled")
      newRepFields.hide()
    }
  }

  handleRepresentativeDocumentsDisplay() {
    var hasRepresentative = $("input#has_representative").is(":checked")
    var repDocuments = $(
      ".uppy-uploader-container:has(.passport-supplier-dashboard-representative_passport), .uppy-uploader-container:has(.passport-supplier-dashboard-behalf_representative)"
    )
    if (!hasRepresentative) {
      repDocuments.hide()
      repDocuments.find("input[type=hidden]").attr("disabled", "disabled")
    } else {
      repDocuments.show()
      repDocuments.find("input[type=hidden]").removeAttr("disabled")
    }
  }

  initHandleRepresentativeDocumentsDisplay() {
    $("input#has_representative").change(
      this.handleRepresentativeDocumentsDisplay
    )
  }

  handleDocumentsRemoveInvalid() {
    var uppy = $(".uppy-uploader-container")
    uppy.on("click", "button", function () {
      var targetContainer = $(this).closest(".uppy-uploader-container")
      targetContainer.find(".invalid").removeClass("invalid")
      targetContainer.find("span.helper-text").remove()
    })
  }

  handleTempConnectionFieldsDisplay() {
    var connectionPeriodFields = $(".js-connection-period-switchable")
    var contractFields = $(".js-contract-switchable")
    var radioButtons = $(
      ".radio_buttons.request_request_connection_connection_type"
    )
    if (
      radioButtons.find('input[type="radio"]:checked').val() ==
      "temporary_connection"
    ) {
      connectionPeriodFields.hide()
      contractFields.show()
    } else if (
      radioButtons.find('input[type="radio"]:checked').val() ==
      "temporary_connection_to_mobile_objects"
    ) {
      connectionPeriodFields.show()
      contractFields.hide()
    }
  }

  initHandleTempConnectionFieldsDisplay() {
    $(".request_request_connection_connection_type input").change(
      this.handleTempConnectionFieldsDisplay
    )
  }

  handleExistingContractDocumentsDisplay() {
    var hasContract = $(
      "#request_request_connection_contract_supplier_attributes_contract_exist"
    ).is(":checked")
    var contractDocuments = $(
      ".uppy-uploader-container:has(.passport-supplier-dashboard-contract_copy)"
    )
    if (!hasContract) {
      contractDocuments.hide()
      contractDocuments.find("input[type=hidden]").attr("disabled", "disabled")
    } else {
      contractDocuments.show()
      contractDocuments.find("input[type=hidden]").removeAttr("disabled")
    }
  }

  initHandleExistingContractDocumentsDisplay() {
    $(
      "#request_request_connection_contract_supplier_attributes_contract_exist"
    ).change(this.handleExistingContractDocumentsDisplay)
  }

  initCommercialApprovalFields() {
    let voltageLevel = $(".js-approval-voltage-level")
    let conditionalFields = $(".js-approval-conditionals")
    if (voltageLevel.length && conditionalFields.length) {
      if (
        !(
          voltageLevel.val().toString() === "6" ||
          voltageLevel.val().toString() === "10" ||
          voltageLevel.val().toString() === "first" ||
          voltageLevel.val().toString() === "second"
        )
      ) {
        conditionalFields.hide()
      }
      voltageLevel.change(function () {
        if (
          $(this).val().toString() === "6" ||
          $(this).val().toString() === "10" ||
          voltageLevel.val().toString() === "first" ||
          voltageLevel.val().toString() === "second"
        ) {
          conditionalFields.show()
        } else if (conditionalFields.is(":visible")) {
          conditionalFields.hide()
        }
      })
    }
  }

  initHeaderRequestStatusesNotifications() {
    $(".header-group__notifications .header-group__item-title").click(
      function () {
        $.ajax({
          url: "/cabinet/request/status_histories_set_read",
          type: "POST",
          dataType: "json",
          success: function (data, status, xhr) {
            console.log(data)
          },

          error: function (data) {
            console.log(data)
          }
        })
      }
    )
  }

  disableStandardTypeSelect() {
    $(
      ".js-standard-type-select-disabled .select-dropdown.dropdown-trigger"
    ).attr("disabled", "disabled")
  }

  hideEmptyDocsContainers() {
    $(".js-docs-container").each(function (index, element) {
      if (!$(element).has(".js-doc-link").length) {
        $(element).hide()
      }
    })
  }

  hideDeletedFile() {
    $(".delete-checkbox span").click(e => {
      $(e.target).closest(".js-doc-link").hide()
    })
  }

  initRequestFeedbackDescription() {
    $("body").on(
      "change",
      "#request_request_feedback_subject",
      this.handleShowRequestFeedbackDescription
    )
    $("body").on(
      "change",
      ".request_request_feedback_subject select",
      this.handleShowRequestFeedbackDescription
    )
  }

  handleShowRequestFeedbackDescription() {
    var arIntervals = {
      to_report_theft: "1 календарного дня",
      complaint: "30 календарных дней",
      gratitude: "",
      offer: "15 календарных дней",
      request_information: "15 календарных дней"
    }

    let requestFeedbackSubjectSelectValue = $(
      "#request_request_feedback_subject"
    )
      .val()
      .toString()
    let requestFeedbackDescription = $(".js-request-feedback-description")
    if (
      requestFeedbackSubjectSelectValue != "" &&
      arIntervals[requestFeedbackSubjectSelectValue] != ""
    ) {
      requestFeedbackDescription.css("display", "block")

      requestFeedbackDescription
        .find(".page-description__text span")
        .html(arIntervals[requestFeedbackSubjectSelectValue])
    } else {
      requestFeedbackDescription.css("display", "none")
    }
  }
}
