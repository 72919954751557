import InputMask from 'inputmask'

export default class Masks {
    constructor(){
        var phoneMask = new InputMask("+7-(999)-999-9999");
        var indexMask = new InputMask("999999");
        var kppMask = new InputMask("999999999");

        var innMaskSoleProprietor = new InputMask("999999999999");
        var innMaskLegalPerson = new InputMask("9999999999");

        var egrulMaskSoleProprietor = new InputMask("999999999999999");
        var egrulMaskLegalPerson = new InputMask("9999999999999");

        var cadastralNumberMask = new InputMask({ mask: ["99:99:9999999", "99:99:9999999:99999"],  jitMasking: 14, keepStatic:false });
        var snilsMask = new InputMask("999-999-999 99");
        var stringMask = new InputMask({regex: "[а-яА-Яa-zA-ZёЁ\\s]+"});
        var numMask = new InputMask({regex: "\\d+"});
        var powerFieldMask = new InputMask({regex: "(([0-9]([0-9]+)?)((\\.?|\\,?)([0-9]+))?"});
        var dateMask = new InputMask("99-99-9999");


        phoneMask.mask($('.js-phone-input'));
        indexMask.mask($('.js-index-input'));

        dateMask.mask($('.js-datepicker'));

        innMaskSoleProprietor.mask($('.js-inn-input--sole-proprietor'));
        innMaskLegalPerson.mask($('.js-inn-input--legal-person'));

        egrulMaskSoleProprietor.mask($('.js-egrul-input--sole-proprietor'));
        egrulMaskLegalPerson.mask($('.js-egrul-input--legal-person'));

        kppMask.mask($('.js-kpp-input'));
        cadastralNumberMask.mask($('.js-cadastral-number-input'));
        stringMask.mask($('#user_surname, #individual_user_surname, #sole_proprietor_user_surname, #legal_person_user_surname'));
        stringMask.mask($('#user_name, #individual_user_name, #sole_proprietor_user_name, #legal_person_user_name'));
        stringMask.mask($('#user_patronymic, #individual_user_patronymic, #sole_proprietor_user_patronymic, #legal_person_user_patronymic'));
        numMask.mask($('#user_passport_series'));
        numMask.mask($('#user_passport_number'));
        snilsMask.mask($('#user_snils, #individual_user_snils'));
        powerFieldMask.mask($('#check_request_to_kenk_sum_value'));
        // numMask.mask($('#user_registration_address_index'));
        powerFieldMask.mask($('#request_request_connection_max_power_previously_attached'));
        powerFieldMask.mask($('#request_request_connection_max_power_optional_attachable'));

        $('#check_request_to_kenk_sum_value').on('change', function () {
            var maxValue = parseFloat($(this).attr('max'));
            if($(this).val() > maxValue){
                $(this).val(maxValue);
            }
        });
    }
  }