export default class CollectionFilter {
    constructor() {
        $(".requests-filter .request-status").click(function(){
            $(".requests-filter .request-status.active").removeClass("active");
            var status = $(this).attr('data-status');
            $(this).addClass("active");

            if(status == 'all'){
                $('.collection-item').addClass('active-status')
            }
            else{
                $('.collection-item').removeClass('active-status');
                $(".collection-item[data-status='" + status +"']").addClass('active-status');
            }
        });


        $('#request_type_filter').change(function () {
            var filterValue = $(this).val();

            if(filterValue && filterValue.length > 0){
                $('.request__type_filtering').css('display', 'none');
                $('.request__type_filtering[data-type-request=' + filterValue + ']').css('display', 'block')
            }
            else{
                $('.request__type_filtering').css('display', 'block');
            }

        });
    }
}