import AjaxFormWidget from "./ajax_form";
import M from "materialize-css";

window.Registration = class Registration {
    constructor() {
        var self = this;
        var formTabBlock = undefined;
        window.registration_individual = new AjaxFormWidget('user', 'new', 'individual');
        window.registration_sole = new AjaxFormWidget('user', 'new', 'sole_proprietor');
        window.registration_legal = new AjaxFormWidget('user', 'new', 'legal_person');

        $('.registration__sms-submit').click(function () {
            var sms_submit_button = $(this);
            var formTabBlock = sms_submit_button.parents('.base-tab-content')[0];
            var formRegistration = $(formTabBlock).find('form');
            var formData = $(formRegistration).serialize();

            var formId = $(formRegistration).attr('id');
            var formIdSpliting = [''];

            if(formId){
                formIdSpliting = formId.split('_');
            }

            var formWidget = window['registration_'+ formIdSpliting[0]];

            $.ajax({
                url: '/validate_create_user',
                type: "GET",
                dataType: 'json',
                data: formData,
                success: function (data, status, xhr) {
                    $('.account-header-block--step-1').removeClass('active');
                    $('.account-header-block--step-2').addClass('active');

                    $(formTabBlock).find('.account__form-step-1').removeClass('active');
                    $(formTabBlock).find('.account__form-step-2').addClass('active');
                    self.initSmsTimer();
                    self.initEmailTimer();

                    if (data.code) {
                        $("<p style='color: white'>Код:" + data.code +"</p>").insertAfter(".registration__description");
                    }
                },

                error: function (data) {
                    var form_data = {detail: [data.responseJSON]};

                    if(formWidget){
                        formWidget.formError(form_data);
                    }
                }
            });
        });

        $('.account-back-button').click(function () {
            $('.account-header-block--step-2').removeClass('active');
            $('.account__form-step-2').removeClass('active');
            $('.account-header-block--step-1').addClass('active');
            $('.account__form-step-1').addClass('active');

            $(formTabBlock).find('.account__form-step-2').removeClass('active');
            $(formTabBlock).find('.account__form-step-1').addClass('active');
        });

        $('.account-tab-button').click(function () {
            var buttonIndex = $(this).attr('data-index');

            $('.account-header-block--step-2').removeClass('active');
            $('.account-header-block--step-1').addClass('active');

            $('.account__form').removeClass('active');
            $('.base-tab-' + buttonIndex).find('.account__form-step-1').addClass('active');
            $('.base-tab-' + buttonIndex).find('.account__form-step-2').removeClass('active')
        });

        $('.send-again-only-email').on('click', function() {
            var formData = $(this).parents('form').first().serialize()
            var sendButton = $(this);

            $.ajax({
                url: '/send_again_only_email',
                type: "GET",
                dataType: 'json',
                data: formData,
                success: function () {
                    sendButton.attr('disabled', true);
                    sendButton.text('Отправлено');

                    const err = $('.email-was-empty').find('.helper-text')
                    err.hide()
                },

                error: function (d) {
                    const data = d.responseJSON
                    if (data.errors && data.errors.email) {
                        const err = $('.email-was-empty').find('.helper-text')
                        err.attr('data-error', data.errors.email[0]);
                        err.show()
                    }
                }
            });
        })

        $('.account__sms-code-submit-now').click(function () {
            var sms_submit_button = $(this);
            var formTabBlock = sms_submit_button.parents('.base-tab-content')[0];
            var formRegistration = $(formTabBlock).find('form');
            var formData = $(formRegistration).serialize();

            $.ajax({
                url: '/validate_create_user',
                type: "GET",
                dataType: 'json',
                data: formData,
                success: function (data, status, xhr) {
                    self.initSmsTimer();
                },

                error: function (data) {
                    console.log(data)
                }
            });
        })

        $('.js-inn-input--registration').on('change', function (event) {
            var innValue = $(this).val();
            var innInput = $(this);

            $.ajax({
                type: "GET",
                dataType: 'json',
                url: '/get_organisation_by_inn',
                data: {inn: innValue},

                success: function(data, status, xhr ) {
                    data = JSON.parse(data.organisation);
                    if(data){
                        var formBlock = $(innInput).parents('.base-form');
                        if(data.ogrn) {
                            $(formBlock).find('.js-egrul-input--registration').val(data.ogrn);
                            $(formBlock).find('.js-egrul-input--registration').focus();
                        }

                        if(data.kpp) {
                            $(formBlock).find('.js-kpp-input--registration').val(data.kpp);
                            $(formBlock).find('.js-kpp-input--registration').focus();
                        }

                        if(data.value) {
                            $(formBlock).find('.js-company-name-input--registration').val(data.value);
                            $(formBlock).find('.js-company-name-input--registration').focus();
                        }

                        M.AutoInit();
                    }
                },

                error: function (data) {
                    console.log(data)
                }
            });

        })
    }
    initEmailTimer() {
        if (this.emailConfirmTimer === 0) {
            return
        }
        
        clearInterval(this.email_interval);
        this.emailConfirmTimer = 60;

        this.email_interval = setInterval(() => {
            if (this.emailConfirmTimer === 0) {
                clearInterval(this.email_interval);
                var emailField = $('[name="user[email]"]')

                if (emailField.val()) {
                    $('.email-was-empty').hide()
                } else {
                    $('.email-was-empty').show()
                    $('.notify-about-email .second-email').on('change', function() {
                        emailField.val($(this).val());
                    })
                }

                $('.notify-about-email').fadeIn(170);
            } else {
                this.emailConfirmTimer -= 1;
            }
        }, 1000);
    }
    initSmsTimer() {
        var time = 180;

        clearInterval(this.timer_interval);

        $('.account__sms-code-timer-block').css('display', 'block');
        $('.account__sms-code-submit-now').css('display', 'none');

        this.timer_interval = setInterval(function () {
            time -= 1;
            $('.account__sms-code-timer').html(new Date(time * 1000).toISOString().substr(14, 5));

            if(time <= 0){
                clearInterval(this.timer_interval);
                $('.account__sms-code-timer-block').css('display', 'none');
                $('.account__sms-code-submit-now').css('display', 'block')
            }
        }, 1000)
    }
};

export default window.Registration