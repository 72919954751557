import M from 'materialize-css'

window.RequestPayment = class RequestPayment {
    constructor() {
        $('.status-payment-description__button').click(function () {
            var paymentUrl = $(this).attr('data-url');

            $.ajax({
                url: paymentUrl,
                type: "GET",
                dataType: 'json',
                success: function (data, status, xhr) {
                    console.log(data);
                    if (data.payment_url) {
                        window.location.replace(data.payment_url)
                    } else {
                        M.toast({html: 'При попытке оплаты произошла ошибка', classes: 'rounded red'});
                    }

                },

                error: function (data) {
                    M.toast({html: data.responseJSON.errors.errorMessage, classes: 'rounded red'});
                    console.log(data.responseJSON.errors);
                }
            });
        });

        $('.status-payment-description__price-block--check .base-button').click(function () {
            $('#check_request_to_kenk_popup').show();
            $(".popup-background").show();
        });

        $('.check_request_to_kenk__confirm-button').click(function () {
            var paymentUrl = $(this).attr('data-url');
            var sum = $('#check_request_to_kenk_sum_value').val();

            $.ajax({
                url: paymentUrl,
                type: "POST",
                dataType: 'json',
                data: {
                    sum: sum
                },
                success: function (data, status, xhr) {
                    M.toast({html: 'Запрос отправлен', classes: 'rounded green'});
                    $('#check_request_to_kenk_popup').hide();
                    $(".popup-background").hide();
                },

                error: function (data) {
                    M.toast({html: data.responseJSON.errors.errorMessage, classes: 'rounded red'});
                }
            });
        })
    }
};

export default window.RequestPayment