/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import * as ActiveStorage from "activestorage"

import "../stylesheets/cabinet.scss"
import M from "materialize-css"
import "cocoon-js"
import AjaxFormWidget from "../javascripts/app/ajax_form"
import Fields from "../javascripts/base/fields"
import Tabs from "../javascripts/base/tabs"
import Dropdown from "../javascripts/base/header"
import BaseForm from "../javascripts/base/form"
import SideMenu from "../javascripts/base/side-menu"
import fileUpload from "../javascripts/app/uploader"
import CollapsibleBlock from "../javascripts/base/collapse"
import CollectionFilter from "../javascripts/base/filters"
import CalculatePower from "../javascripts/app/calculator_power"
import Masks from "../javascripts/base/masks"

import AdminBookTranslation from "../javascripts/app/EditRequestType"
import NewRequestForm from "../javascripts/base/requests"
import PlannedShutdownFilter from "../javascripts/app/planned_shutdown_filter"
import ProcedureCalculator from "../javascripts/app/procedure_calculator"
import RequestPayment from "../javascripts/app/request_payment"
import Registration from "../javascripts/app/registration"
import AskueIndication from "../javascripts/app/askue/askue_indications"
import AskueHourlyReadings from "../javascripts/app/askue/askue_hourly_readings"
import AskueExportXls from "../javascripts/app/askue/askue_export_xls"
import AddressSearch from "../javascripts/app/address_search"

Rails.start()
ActiveStorage.start()

const pagePath = window.location.pathname
var actionType = ""
jQuery(function ($) {
  M.AutoInit()
  window.baseFormObject = new BaseForm()
  new Tabs()
  new Fields()
  new Dropdown()
  new SideMenu()
  new AdminBookTranslation()
  new CalculatePower()
  new RequestPayment()
  new Masks()
  new Registration()
  new AskueExportXls()
  new AddressSearch()

  if (pagePath.search("/askue/indications") === 0) {
    new AskueIndication()
  }

  if (pagePath.search("/askue/hourly_reading") === 0) {
    new AskueHourlyReadings()
  }

  if (pagePath.localeCompare("/user/edit") == 0) {
    new AjaxFormWidget("user", "edit")
    new AjaxFormWidget("organisation", "edit")
  }
  var regRequestConnection = new RegExp(
    "/cabinet/request/request_connections/[0-9]+/edit"
  )
  if (pagePath.search(regRequestConnection) >= 0) {
    new AjaxFormWidget("request_request_connection", "edit")
    document
      .querySelectorAll(".request-connection-document-upload-file")
      .forEach(function (fileInput) {
        fileUpload(fileInput)
      })
  }

  var regHistoryStatusPage = new RegExp("/cabinet/request/history_status/[0-9]")
  if (pagePath.search(regHistoryStatusPage) >= 0) {
    new AjaxFormWidget("request_connection_document", "new")
    new AjaxFormWidget(
      "request_connection_document",
      "new",
      "notice_of_technical_specifications"
    )
    new AjaxFormWidget("request_letter_message", "new")
    $(".request-contract_signed_user-upload-file").each(function () {
      fileUpload(this)
    })
  }

  var regRejectRequestPage = new RegExp(
    "cabinet/request/request_connections/reject_request/*"
  )
  var regRejectContractPage = new RegExp(
    "cabinet/request/request_connections/reject_contract/*"
  )
  if (
    pagePath.search(regRejectRequestPage) >= 0 ||
    pagePath.search(regRejectContractPage) >= 0
  ) {
    new AjaxFormWidget("request_letter_message", "new")
  }

  var regHistoryStatusAdminPage = new RegExp("admin/*")
  if (pagePath.search(regHistoryStatusAdminPage) >= 0) {
    new AjaxFormWidget("request_connection_document", "new")
    $(".request-contract_signed_user-upload-file").each(function () {
      fileUpload(this)
    })
  }

  if (pagePath.search("/cabinet/connection_objects/new") == 0) {
    new AjaxFormWidget("connection_object", "new")
  }

  const namesArray = [
    "agreement",
    "approval",
    "installation",
    "send_reading",
    "replacement",
    "assessment",
    "service",
    "reading",
    "standard",
    "quality_rate",
    "connection_rate"
  ]
  namesArray.forEach(name => {
    if (pagePath.search("/cabinet/request/request_" + name + "s/new") === 0) {
      new AjaxFormWidget("request_request_" + name, "new")
      document
        .querySelectorAll(
          ".request-commercial-document-upload-file, .request-standard-document-upload-file"
        )
        .forEach(function (fileInput) {
          fileUpload(fileInput)
        })
      actionType = "new"
      new NewRequestForm(actionType)
    } else if (
      pagePath.search("/cabinet/request/request_" + name + "s/[0-9]+/edit") ===
      0
    ) {
      new AjaxFormWidget("request_request_" + name, "edit")
      document
        .querySelectorAll(
          ".request-commercial-document-upload-file, .request-standard-document-upload-file"
        )
        .forEach(function (fileInput) {
          fileUpload(fileInput)
        })
      actionType = "edit"
      new NewRequestForm(actionType)
    } else if (
      pagePath.search("/cabinet/request/request_" + name + "s/[0-9]+/") === 0
    ) {
      actionType = "show"
      new NewRequestForm(actionType)
    }
  })

  new NewRequestForm(actionType)

  if (pagePath.search("/cabinet/request/request_feedbacks/new") === 0) {
    document
      .querySelectorAll(".request-feedback-upload-file")
      .forEach(function (fileInput) {
        fileUpload(fileInput)
      })
  }

  if (pagePath.match(/\d/gi) != null) {
    new AjaxFormWidget("connection_object", "edit")
  }

  if (
    pagePath.localeCompare(
      "/cabinet/request/request_connections/new/permanent_connection"
    ) == 0 ||
    pagePath.localeCompare(
      "/cabinet/request/request_connections/new/temporary_connection"
    ) == 0 ||
    pagePath.localeCompare(
      "/cabinet/request/request_connections/new/temporary_connection_to_mobile_objects"
    ) == 0 ||
    pagePath.localeCompare(
      "/cabinet/request/request_connections/new/reissuance"
    ) == 0
  ) {
    new AjaxFormWidget("request_request_connection", "new")
    new AjaxFormWidget("request_request_connection", "edit")

    document
      .querySelectorAll(".request-connection-document-upload-file")
      .forEach(function (fileInput) {
        fileUpload(fileInput)
      })
  }

  new CollapsibleBlock()
  new CollectionFilter()
  new AjaxFormWidget("user", "edit")
  new AjaxFormWidget("organisation", "edit")
  new AjaxFormWidget("user", "new")
  new AjaxFormWidget("request_request_connection_rate", "new")
  new AjaxFormWidget("request_request_quality_rate", "new")
  new AjaxFormWidget("request_request_reception", "new")
  new AjaxFormWidget("request_request_feedback", "new")

  // Редиректы для админки
  new AjaxFormWidget("planned_shutdown", "new")
  new AjaxFormWidget("planned_shutdown", "edit")
  new AjaxFormWidget("faq_type", "new")
  new AjaxFormWidget("faq_type", "edit")
  new AjaxFormWidget("faq", "new")
  new AjaxFormWidget("faq", "edit")
  new AjaxFormWidget("request_standard_category", "new")
  new AjaxFormWidget("request_standard_category", "edit")
  new AjaxFormWidget("request_standard_type", "new")
  new AjaxFormWidget("request_standard_type", "edit")
  new AjaxFormWidget("office_kenk", "new")
  new AjaxFormWidget("office_kenk", "edit")
  new AjaxFormWidget("request_connection_rate", "edit") // переадрисация не работает
  new AjaxFormWidget("procedure_calculator_text", "new")
  new AjaxFormWidget("procedure_calculator_text", "edit")
  new AjaxFormWidget("request_status_history", "new")

  new PlannedShutdownFilter()
  new ProcedureCalculator()
})
