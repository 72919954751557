window.ProcedureCalculator = class ProcedureCalculator {
    constructor() {
        $('.procedure-calculator-type').on('change', function () {
            var blockId = $(this).val();
            $('.procedure-calculator__content').css('display', 'none');
            $('.procedure-calculator__content--' + blockId).css('display', 'block')
        });
    }
};

export default window.ProcedureCalculator