import M from "materialize-css"

window.AjaxFormWidget = class AjaxFormWidget {
  constructor(formName, typeForm, namespace = "") {
    this.formName = formName
    this.typeForm = typeForm
    this.namespace = namespace
    // Формируется id формы из типа формы(new, create) и ее названия
    if (this.namespace.length > 0) {
      this.namespace = this.namespace + "_"
    }

    this.formBlock =
      "[id^=" + this.namespace + this.typeForm + "_" + this.formName + "]"
    this.errorLabel = "custom_error" // Сообщение об ошибке
    this.errorInput = "invalid" // Поле с ошибкой

    this.formBlockSelector = $(this.formBlock)

    this.formBlockSelector.bind("ajax:error", e => {
      this.formError(e)
    })
    this.formBlockSelector.on("ajax:success", e => {
      this.formSuccess(e)
    })

    this.initSigningRequestButton()

    var self = this
    $(this.formBlockSelector.find(".request-history__sms-popup-show")).click(
      function () {
        var path_array = window.location.pathname.split("/")

        var request_id = path_array[path_array.length - 1]

        if (request_id === "edit") {
          request_id = path_array[path_array.length - 2]
        }

        $.ajax({
          url: "/cabinet/resent_sms_code_request",
          type: "POST",
          dataType: "json",
          data: { request_id: request_id },
          success: function (data, status, xhr) {
            self.initSmsTimer()
            if (data["code"]) {
              $("<p>Код:" + data.code + "</p>").insertAfter(".sms-popup__text")
            }
          },

          error: function (data) {
            console.log(data)
          }
        })
        console.log(path_array, path_array[path_array.length - 1])
        self.showSmsPopup({
          request_id: request_id,
          location: window.location.pathname,
          type_popup: "history_confirmation"
        })
      }
    )
  }

  formError = e => {
    let data = e.detail[0] // массив с ошибками
    // const status = e.detail[1] // строка статуса ответа от сервера
    // const xhr = e.detail[2] // данные запроса
    this.removeErrors()
    var nested_form = data.nested_form
    var documents = data.documents
    // console.log(data.errors);

    console.log(e)
    if (nested_form) {
      this.handleNestedFormErrors(data)
    } else if (documents) {
      this.handleDocumentsFormErrors(data)
    } else {
      if (data.errors != undefined) {
        data = data.errors
      }
      for (var field in data) {
        if (field === "base") {
          M.toast({ html: data["base"], classes: "rounded red" })
        } else {
          const fieldValue = data[field]
          if (field === 'sms_code') {
            $(".profile-form-confirmation-phone").css("display", "block")
            $(".js-phone-profile-confirmation").css("display", "block")
          }

          var fieldArray = field.split(".")
          var errorInput = undefined
          if (fieldArray.length > 1) {
            if (
              fieldArray[0] === "companion" &&
              this.formName === "request_request_quality_rate"
            ) {
              errorInput = $(
                $(
                  ".base-form__fields-wrapper:has(#" +
                    this.namespace +
                    this.formName +
                    "_" +
                    fieldArray[1] +
                    ")"
                )[0]
              )
            } else {
              errorInput = $(
                "[id^=" +
                  this.namespace +
                  this.formName +
                  "_" +
                  fieldArray[0] +
                  "_attributes_" +
                  "][id$=" +
                  "_" +
                  fieldArray[1] +
                  "]"
              )
            }
          } else if (/custom.*/.test(field)) {
            errorInput = $(
              $("#_" + field)[0]
            )
          } else {
            errorInput = $(
              $("#" + this.namespace + this.formName + "_" + field)[0]
            )
          }

          errorInput.addClass(this.errorInput)
          if (fieldArray[0] === "user_id") {
            $("#user-error").addClass("invalid")
            $("#user-error").text(fieldValue[0])
          } else if (errorInput.is(".base-form__fields-wrapper")) {
            fieldValue.forEach((mes) => {
              $(errorInput)
                .find(".col h4")
                .after(
                  '<span class="helper-text" data-error="' + mes + '"></span>'
                )
            })
          } else if (Array.isArray(fieldValue) && !documents) {
            fieldValue.forEach((mes) => {
              $(errorInput.parents(".base-input")[0]).after(
                '<span class="helper-text" data-error="' + mes + '"></span>'
              )
              $(errorInput.parents(".base-form__checkbox-wrapper")[0]).after(
                '<span class="helper-text helper-text--checkbox" data-error="' +
                  mes +
                  '"></span>'
              )
            })
          }
        }
      }
    }

    var firstErrorTab = $($(".invalid:first").parents(".base-tab-content")[0])
    if (firstErrorTab.length) {
      var tabIndex = firstErrorTab.attr("data-tab-content-index")
      $(".base-tab-button").removeClass("base-tab_checked")
      $(".base-tab-content").removeClass("is-visible")
      firstErrorTab.addClass("is-visible")
      $(".base-tab-button--" + tabIndex).addClass("base-tab_checked")

      $("html, body").animate(
        {
          scrollTop: $(".invalid:first").offset().top
        },
        500
      )
    }
  }

  handleNestedFormErrors = data => {
    if (data.errors != undefined) {
      data = data.errors
    }
    var name_nested_form = Object.keys(data)[0]
    data = data[name_nested_form]

    for (var field in data) {
      const fieldValue = data[field]
      var fieldArray = field.split(".")
      var errorInput = undefined

      if (fieldArray.length > 1) {
        errorInput = $(
          "[id^=" +
            name_nested_form +
            "_" +
            fieldArray[0] +
            "_attributes_" +
            "][id$=" +
            "_" +
            fieldArray[1] +
            "]"
        )
      } else {
        errorInput = $("#" + name_nested_form + "_" + field)
      }

      errorInput.addClass(this.errorInput)

      if (Array.isArray(fieldValue)) {
        fieldValue.forEach((mes, i, arr) => {
          errorInput.after(
            '<span class="helper-text" data-error="' + mes + '"></span>'
          )
        })
      }
    }
  }

  handleDocumentsFormErrors = data => {
    if (data.errors != undefined) {
      data = data.errors
    }
    $(".uppy-uploader-container input.invalid").removeClass("invalid")
    $(".uppy-uploader-container span.helper-text").remove()

    for (var field in data) {
      const fieldValue = data[field]
      var errorInput = undefined
      for (var doc in fieldValue) {
        errorInput = $("#documents_files_" + doc)
        errorInput
          .siblings("h6")
          .after(
            '<span class="helper-text" data-error="Не может быть пустым"></span>'
          )
        errorInput.addClass("invalid")
      }

      if (Array.isArray(fieldValue)) {
        fieldValue.forEach((mes, i, arr) => {
          errorInput.after(
            '<span class="helper-text" data-error="' + mes + '"></span>'
          )
        })
      }
    }
  }

  formSuccess = e => {
    this.removeErrors()
    const data = e.detail[0] // массив с ошибками
    // const status = e.detail[1] // строка статуса ответа от сервера
    // const xhr = e.detail[2] // данные запроса\
    if (data["code"]) {
      $("<p>Код:" + data["code"] + "</p>").insertAfter(".sms-popup__text")
    }

    if (data["message"]) {
      M.toast({ html: data["message"], classes: "rounded green" })
      return
    }

    if (data["saveDraft"] || data["update_flag"]) {
      this.successRedirect(data["location"])
    } else if (!this.showSmsPopup(data)) {
      this.successActions(data)
    }
  }

  initSigningRequestButton = () => {
    var self = this

    $(".js-cabinet_request_signing_request-button").each(function () {
      if (!$(this).attr("data-init")) {
        $(this).attr("data-init", true)

        $(this).click(function () {
          var request_id = $(this).attr("data-request-id")

          self.showSmsPopup({
            request_id: request_id,
            location: window.location.pathname
          })

          $.ajax({
            url: "/cabinet/resent_sms_code_request",
            type: "POST",
            dataType: "json",
            data: { request_id: request_id },
            success: function (data, status, xhr) {},
            error: function (data) {
              console.log(data)
              self.showSmsError()
            }
          })
        })
      }
    })
  }

  showSmsError = () => {
    $("#sms-request-confirm").hide()
    $("#sms-request-error").show()
  }

  showSmsPopup = data => {
    var self = this
    var formData = data
    if ($("#sms-request-confirm").length) {
      var maxPower = parseInt($(".max-power__val").html())

      if (maxPower > 150) {
        $(".sms-request-confirm--legal").css("display", "block")
        $(".sms-request-confirm--individual").css("display", "none")
      } else {
        $(".sms-request-confirm--legal").css("display", "none")
        $(".sms-request-confirm--individual").css("display", "block")
      }

      $("#sms-request-confirm").show()
      $(".popup-background").show()
      self.initSmsTimer()

      var signedFlag = true
      $("#sms-request-confirm .sms-popup__confirm-button").click(function () {
        if (signedFlag) {
          signedFlag = false
          var sms_code_input = $(this)
            .parents(".sms-popup")
            .find(".sms-request-field")
          var code_sms = sms_code_input.val()

          // Отправка для подтверждения в истории
          if (formData.type_popup == "history_confirmation") {
            $.ajax({
              url: "/cabinet/request_sms_confirmation_history",
              type: "POST",
              dataType: "json",
              data: { code_sms: code_sms, request_id: formData["request_id"] },
              success: function (data, status, xhr) {
                self.formBlockSelector
                  .find(".request-history__sms-confirmed")
                  .css("display", "block")
                self.formBlockSelector
                  .find(".request-history__sms-popup-show")
                  .css("display", "none")
                $("#sms-request-confirm").hide()
                $(".popup-background").hide()
                signedFlag = true
              },

              error: function (data) {
                $(".helper-text").hide()
                sms_code_input.after(
                  '<span class="helper-text" data-error="Не верный код подтверждения"></span>'
                )
                signedFlag = true
              }
            })
          }
          // Отправка для подтверждения создания заявки
          else {
            $(this.formBlock)
              .find(".base-form__buttons-block")
              .css("display", "none")
            $.ajax({
              url: "/cabinet/request_sms_confirmation",
              type: "POST",
              dataType: "json",
              data: { code_sms: code_sms, request_id: formData["request_id"] },
              success: function (data, status, xhr) {
                self.successActions(formData)
                signedFlag = true
              },

              error: function (data) {
                $(".helper-text").hide()
                sms_code_input.after(
                  '<span class="helper-text" data-error="Не верный код подтверждения"></span>'
                )
                signedFlag = true
              }
            })
          }
        }
      })

      $(".sms-popup__send-new").click(function () {
        $.ajax({
          url: "/cabinet/resent_sms_code_request",
          type: "POST",
          dataType: "json",
          data: { request_id: formData["request_id"] },
          success: function (data, status, xhr) {
            self.initSmsTimer()
            console.log(data)
          },

          error: function (data) {
            console.log(data)
          }
        })
      })

      $(".sms-popup__close").click(function () {
        $("#sms-request-confirm").hide()
        $("#sms-request-error").hide()
        $("#check_request_to_kenk_popup").hide()
        $(".popup-background").hide()
        window.location.href =
          "/cabinet/request/history_status/" + formData["request_id"]
      })

      $("#sms-request--to-request").click(function () {
        window.location.href =
          "/cabinet/request/history_status/" + formData["request_id"]
      })

      return true
    }
    return false
  }

  successActions = data => {
    var self = this
    M.toast({ html: "Сохранено", classes: "rounded green" })
    if (data["location"]) {
      if ($(".success-popup").length) {
        $(".success-popup").show()
        $(".popup-background").show()
        $(".success-popup .success-popup__confirm-button").click(
          function () {
            this.successRedirect(data["location"])
          }.bind(this)
        )
      } else {
        setTimeout(function () {
          self.successRedirect(data["location"])
        }, 100)
      }
    } else {
      // M.toast({html: 'Сохранено', classes: 'rounded green'});
    }
  }

  removeErrors = () => {
    $("." + this.errorLabel).remove()
    $("." + this.errorInput).removeClass(this.errorInput)
    $(".helper-text").remove()
  }

  successRedirect = location => {
    const redirectUrl = new URL(
      window.location.protocol + "//" + window.location.host + location
    )
    window.location.href = redirectUrl
  }

  initSmsTimer() {
    var time = 180

    $(".sms-popup__send-timer").css("display", "block")
    $(".sms-popup__send-new").css("display", "none")

    var timer_interval = setInterval(function () {
      time -= 1
      $(".sms-popup__send-timer-time").html(
        new Date(time * 1000).toISOString().substr(14, 5)
      )

      if (time <= 0) {
        clearInterval(timer_interval)
        $(".sms-popup__send-timer").css("display", "none")
        $(".sms-popup__send-new").css("display", "block")
      }
    }, 1000)
  }
}

export default window.AjaxFormWidget
